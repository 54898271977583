<template>
    <div class="container">
        <router-link class="container-function" :to="{ name: 'alertValidade' }">
            <div class="function">
                <div class="function-number">
                    <h3>{{ this.totalTarefas }}</h3>
                </div>
                <img src="../assets/img/task-white.svg" alt="inclusão" />
                <h3>Tarefas</h3>
            </div>
        </router-link>
        <router-link class="container-function" :to="{ name: 'produtos' }">
            <div class="function">
                <img src="../assets/img/add-white.svg" alt="inclusão" />
                <h3>Inclusão</h3>
            </div>
        </router-link>
        <router-link class="container-function" :to="{ name: 'trocar.loja' }">
            <div class="function">
                <img src="../assets/img/map-pin.svg" alt="inclusão" />
                <h3>Trocar de Loja</h3>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    data() {
        return {
            totalAuditoria: null,
            totalRecebimentos: null,
            totalTarefas: null,
        };
    },

    computed: {
        getTotalTarefas() {
            return this.$store.getters.getTotalTarefas;
        },
    },

    created() {
        this.totalTarefas = this.getTotalTarefas;
    },
};
</script>

<style scoped>
.container {
    margin: 66px 20px 57px 20px;
    display: grid;
    place-items: center;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    /* row-gap: 1.5rem; */
    min-height: 20rem;
}

.function {
    display: flex;
    position: relative;
    flex-direction: column;
    place-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    color: #fff;
    border: 1px solid var(--azul-escuro);
    border-radius: 10px;
    background-color: var(--verde);
    padding: 15px;
    min-height: 5rem;
    max-height: 5rem;
    min-width: 6rem;
    max-width: 6rem;
    gap: 10px;
    width: fit-content;
    box-shadow: -6px 7px 0px 0px var(--azul-escuro);
}

.function:hover {
    background-color: var(--azul-escuro);
    border: 1px solid var(--verde);
    box-shadow: -6px 7px 0px 0px var(--verde);
    position: relative;
    top: 5px;
}

.function h3 {
    font-size: 0.8rem;
}

.function-number {
    position: absolute;
    display: flex;

    top: -18px;
    right: -18px;
}

.function-number h3 {
    width: 2rem;
    height: 2rem;
    color: var(--vermelho);
    font-weight: bold;
    display: flex;
    place-content: center;
    place-items: center;
    font-size: 0.8rem;
    border: 1px solid var(--azul-escuro);
    border-radius: 100%;
    padding: 10px;
    background-color: var(--cinza-claro);
}

@media (max-width: 289px) {
    .container {
        column-gap: 0;
    }
    .function {
        min-height: 5rem;
        max-height: 5rem;
        min-width: 5rem;
        max-width: 5rem;
    }

    .function h3 {
        font-size: 0.8rem;
    }
}
</style>
